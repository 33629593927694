/* Style all font awesome icons */
.fa {
    padding-left: 30px !important;
    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    color:black;
  }
  
  .unityicon{
    padding: 0px;
    max-height:64px;
    max-width: 64px;
  }

  .Discordicon{
    padding: 0px;
    padding-left:10px;
    max-height:40px;
    max-width: 50px;
  }

  /* Add a hover effect if you want */
  .fa:hover {
    opacity: 0.7;
    color:blue;
  }

  .bar{
    background-color: black;
  }
  
  /* Set a specific color for each brand */
  