html,body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height:100%;
}

#root {
  height:100%;
}
